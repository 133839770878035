<template>
  <div class="text-center">
    <div class="row no-gutters">
      <div class="col pb-2">
        <nav aria-label="...">
          <ul class="pagination text-smallest mb-0 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setCompaniesToTable(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setCompaniesToTable(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setCompaniesToTable(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-3 pb-2">
        <input type="text" name="vesselSearch" class="form-control form-control-sm" v-model="params.search" placeholder="Search Company">
      </div>
    </div>
    <div class="row no-gutters" style="height: 80vh; overflow-y: auto">
      <div class="col-sm-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th class="align-middle cursor-pointer" rowspan="2" style="width: 1em" scope="col">#</th>
            <th class="align-middle cursor-pointer" rowspan="2" scope="col" @click="sorterArrowSetDirection('name')">
              COMPANY NAME
              <sorter-arrow
                :keyInList="'name'"
                :array-list-length="companies.length"
                :current-order-by="currentOrderBy"
                @runCallBack="runCallBack"
              >
              </sorter-arrow>
            </th>
            <th class="align-middle cursor-pointer" rowspan="2" scope="col" @click="sorterArrowSetDirection('country_id')">
              COUNTRY
              <sorter-arrow
                :keyInList="'country_id'"
                :array-list-length="companies.length"
                :current-order-by="currentOrderBy"
                @runCallBack="runCallBack"
              >
              </sorter-arrow>
            </th>
            <th class="align-middle cursor-pointer" rowspan="2" scope="col" @click="sorterArrowSetDirection('management_office_id')">
              <sorter-arrow
                :keyInList="'management_office_id'"
                :array-list-length="companies.length"
                :current-order-by="currentOrderBy"
                @runCallBack="runCallBack"
              >
              </sorter-arrow>
              MANAGEMENT
            </th>
            <th class="align-middle" colspan="2" scope="col">LAST AUDIT</th>
            <th style="width: 70px" rowspan="2" scope="col">ACTION</th>
          </tr>
          <tr>
            <th class="align-middle" scope="col">ADDRESS</th>
            <th class="align-middle" scope="col">DATE</th>
          </tr>
          </thead>
          <tbody>

          <tr v-if="loading">
            <td colspan="100%">
              <app-loading></app-loading>
            </td>
          </tr>

          <template v-else>
            <tr v-for="(company, index) in companies">
              <td class="align-middle text-uppercase">{{index + 1 }}</td>
              <td class="align-middle text-uppercase">{{ company.name }}</td>
              <td class="align-middle text-uppercase">{{ company.country.name }}</td>
              <td class="align-middle text-uppercase">{{ company.management.code }}</td>
              <td class="align-middle text-uppercase">{{ company.last_audit.address }}</td>
              <td class="align-middle text-uppercase">{{ company.last_audit.date }}</td>
              <td class="align-middle py-1">
                <div class="dropdown dropright">
                  <button
                    id="actionDropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="mr-2 btn btn-sm btn-secondary force-white-all"
                    data-toggle="dropdown"
                  >
                    <font-awesome-icon icon="cogs"/>
                  </button>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" style="min-height: 50px">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            @click="viewCompanyAuditRecord(company.id)">
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            @click="viewCompanyAuditRecordNewTab(company.id)">
                      <font-awesome-icon icon="eye"/>
                      View on New Tab
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          </tbody>
        </table>
      </div>
    </div>
    <add-new-company />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {vueAppMixin} from "@/mixins/vueAppMixin";
import AppLoading from "@/components/elements/AppLoading";

import _ from 'lodash';
import {permissionMixin} from "@/mixins/permissionMixins";
import {AlertService} from "@/services/AlertService";
import {PaginationMixin} from "@/mixins/PaginationMixin";

import CompanyAuditMixin from "@/mixins/CompanyAuditMixin";
import AddNewCompany from "@/components/modules/ia/company/AddNewCompany.vue";
import SorterArrow from "@/components/common/SorterArrow.vue";
import SorterArrowMixin from "@/mixins/SorterArrowMixin";

export default {

  mixins: [vueAppMixin,permissionMixin,PaginationMixin, CompanyAuditMixin, SorterArrowMixin],
  components: {AppLoading, AddNewCompany, SorterArrow},
  name: 'CompanyList',
  data() {
    return {
      params: {
        active: 1,
        page:1,
        search:''
      },
      loading: false,
    }
  },
  methods: {
    ...mapActions([
      'getCompaniesList',
      'deleteCompany'
    ]),
    async viewCompanyAuditRecord(companyAuditRecordId) {
      await this.$router.push(
        {
          name: 'CompanyAuditRecordList',
          params: {
            company_id: companyAuditRecordId,
          },
        },

      ).catch(() => {})
    },
    async viewCompanyAuditRecordNewTab(companyAuditRecordId) {
      const route= this.$router.resolve(
        {
          name: 'CompanyAuditRecordList',
          params: {
            company_id: companyAuditRecordId,
          },
        },
      )
      window.open(route.href,'_blank')
    },
    async setCompaniesToTable(page=1) {
      this.loading = true;
      this.params.page=page;
      await this.getCompaniesList(this.params);
      this.loading = false;
    },
    runCallBack(param) {
      this.params.orderBy = param.orderBy;
      this.params.direction = param.direction;
      if (param.direction === '') {
        this.currentOrderBy = '';
      }
      this.setCompaniesToTable(1);
    }
  },
  computed: {
    ...mapGetters([
      'pagination',
      'companies',
    ])
  },
  async created() {
    this.setCompanies = _.debounce(this.setCompaniesToTable, 750)
    await this.setCompaniesToTable();

  },
  watch: {
    'params.active'() {
      this.setCompanies()
    },
    'params.search'(){
      this.setCompanies();
    }
  },

  destroyed() {
    this.params.active = 1;
  }

}
</script>

<style scoped lang="scss">
.e-tbl  thead tr th {
  border: 1px solid #b1b1b1;
}
</style>
